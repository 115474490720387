import React from "react";
import './index.css'
import img1 from '../../assets/img/Homepage/digital-transformation.png'
import img2 from '../../assets/img/Homepage/operational.png'
import img3 from '../../assets/img/Homepage/project-management.png'
import img4 from '../../assets/img/Homepage/satisfaction.png'
import img5 from '../../assets/img/Homepage/tech_adoption.png'
const Comprehensive = () => {
  return (
    
      <div class="bg-[#0f172a] py-12 ">
  {/* <!-- Hero Unit --> */}
<section class="hero-unit">

  <div class="row">
    <div class="large-12 columns">
      <hgroup className=" py-10 px-10 text-center">
        <h1 className=" text-5xl text-white font-bold">Comprehensive <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-700 via-blue-800 to-gray-100">Maturity Model Assessment</span></h1>
        <h3 className=" text-xl text-slate-100 text-center font-semibold  pt-5">
        Maturity Spectrum 360 utilizes a sophisticated AI-driven methodology to evaluate your organization's current maturity level. By analyzing your practices, processes, and performances against established maturity models, our platform provides a clear, quantifiable measure of where you stand in your transformation journey. This assessment covers multiple areas, including but not limited to:
        </h3>
      </hgroup>


      <ul class="flex flex-wrap justify-center flip-cards px-5">

        <li ontouchstart="this.classList.toggle('hover');">
          <div class="large button text-center card-front">
            <a >Digital Transformation</a>
            <img src={img1} className=" w-14 card-icon"></img>
          </div>
         
        </li>

        <li ontouchstart="this.classList.toggle('hover');">
          <div class="large button text-center card-front">
            <a >Operational Efficiency</a>
            <img src={img2} className=" w-14 card-icon"></img>
          </div>

        
        </li>

        <li ontouchstart="this.classList.toggle('hover');">
          <div class="large button text-center card-front">
            <a >Innovation Management</a>
            <img src={img3} className=" w-14 card-icon"></img>
          </div>

         
        </li>

        <li ontouchstart="this.classList.toggle('hover');">
          <div class="large button text-center card-front">
            <a >Customer Experience</a>
            <img src={img4} className=" w-14 card-icon"></img>
          </div>

         
        </li>

        <li ontouchstart="this.classList.toggle('hover');">
          <div class="large button text-center card-front">
            <a >Technology Adoption</a>
            <img src={img5} className=" w-14 card-icon"></img>
          </div>

       
        </li>

     

      </ul>
    </div>

  

  </div>
</section>
</div>
 
  );
};

export default Comprehensive;
