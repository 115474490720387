import React,{useState} from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'



export default function Index() {
  const [activeTab, setActiveTab] = useState('gettingStarted');

  const tabs = [
    { id: 'gettingStarted', title: 'Getting Started' },
    { id: 'assessmentSupport', title: 'Assessment & Support' },
    { id: 'accounts', title: 'Accounts' },
    { id: 'pricingLicense', title: 'Pricing & License' },
  ];

  const tabContent = {
    gettingStarted: [
      {
        question: "What is Maturity Spectrum 360, and how can it benefit my organization?",
        answer: "Maturity Spectrum 360 is a comprehensive platform that covers various industry standard maturity models for Center of Excellence (CoE) teams, including Agile, Cloud, DevOps, SRE, and more. It helps organizations improve their overall maturity level in these domains by providing tailored recommendations powered by AI. By using Maturity Spectrum 360, you can benchmark your organization's performance, compare it with industry standards, and identify areas for improvement.",
      },
      {
        question: "How do I get started with Maturity Spectrum 360?",
        answer: "To get started, visit our website at www.maturityspectrum360.com and sign up for a free trial. Once you're logged in, you can access the platform and begin assessing your organization's maturity in your chosen domains. You can also explore our license plans to find the right fit for your organization's needs.",
      },
      {
        question: "What types of recommendations does Maturity Spectrum 360 provide, and how are they generated?",
        answer: "To get started, visit our website at www.maturityspectrum360.com and sign up for a free trial. Once you're logged in, you can access the platform and begin assessing your organization's maturity in your chosen domains. You can also explore our license plans to find the right fit for your organization's needs.",
      },
      {
        question: "Can I compare my organization's performance with peers in the same industry?",
        answer: "To get started, visit our website at www.maturityspectrum360.com and sign up for a free trial. Once you're logged in, you can access the platform and begin assessing your organization's maturity in your chosen domains. You can also explore our license plans to find the right fit for your organization's needs.",
      },
      {
        question: "Is there customer support available if I have questions or need assistance?",
        answer: "To get started, visit our website at www.maturityspectrum360.com and sign up for a free trial. Once you're logged in, you can access the platform and begin assessing your organization's maturity in your chosen domains. You can also explore our license plans to find the right fit for your organization's needs.",
      },
    
    ],
    assessmentSupport: [
      {
        question: "Can we engage AeroAegis in-house consultants to assess and improve our organization's maturity levels using the Maturity Spectrum 360 platform?",
        answer: "Yes, AeroAegis offers a team of Subject Matter Experts (SMEs) who can assess your organization's maturity levels and work closely with your team to implement the recommendations provided by the Maturity Spectrum 360 platform. Our consultants bring extensive experience and expertise to drive maturity improvements.",
      },
      {
        question: "Does Maturity Spectrum 360 collaborate with third-party consultants like Accenture, HCL, or Infosys for maturity assessments and recommendations?",
        answer: "Absolutely, Maturity Spectrum 360 is designed to accommodate third-party consultants and their assessments. The platform can be onboarded with external consultants and Subject Matter Experts (SMEs) from leading consulting firms to provide assessments and assist in implementing recommendations.",
      },
      {
        question: "Can our organization perform self-assessments using Maturity Spectrum 360, especially if we have in-house Centers of Excellence (CoE) with SMEs?",
        answer: "Yes, Maturity Spectrum 360 empowers organizations to conduct self-assessments using customized maturity models. If your organization has in-house Centers of Excellence with Subject Matter Experts (SMEs), you can create and assess your unique maturity frameworks tailored to your specific needs.",
      },
      {
        question: "Does the platform provide AI-based assessment capabilities?",
        answer: "Yes, Maturity Spectrum 360 offers AI-based assessment capabilities, allowing organizations to assess each practice using AI-driven maturity models. The AI component enhances the accuracy and depth of assessments, providing valuable insights and recommendations.",
      },
      {
        question: "What assessment options are available with Maturity Spectrum 360?",
        answer: "Maturity Spectrum 360 provides multiple assessment options, including selfassessment, third-party or external assessment by consulting firms, internal assessments by AeroAegis consultants, and AI-based assessments. Organizations can choose the assessment method that best suits their requirements and resources.",
      },
      {
        question: "Is Maturity Spectrum 360 limited to specific maturity models, or does it cover a wide range of domains and practices?",
        answer: "Maturity Spectrum 360 is comprehensive and covers a broad spectrum of maturity models, from agile methodologies to cloud adoption, AI and machine learning, DevOps, SRE (Site Reliability Engineering), Big Data, Virtual Reality (VR), Augmented Reality (AR), and more. It caters to diverse domains and practices.",
      },
      {
        question: "Can our organization create a customized maturity model framework using the Maturity Spectrum 360 platform?",
        answer: "Yes, organizations have the flexibility to create and customize their own maturity model frameworks within the Maturity Spectrum 360 platform. This allows you to align maturity assessments precisely with your unique business needs and practices.",
      },
      {
        question: "What kind of insights and recommendations does the platform provide to improve maturity levels?",
        answer: "Maturity Spectrum 360 offers tailored recommendations powered by AI and industry benchmarking. These recommendations can include specific action plans, best practices, and insights to enhance maturity levels across different practices or domains.",
      },
    
      {
        question: "Is there an option for organizations to benchmark their maturity against industry standards and peer organizations?",
        answer: "Yes, the platform includes industry benchmarking features that enable organizations to compare their maturity levels with industry standards and the performance of peer organizations in the same domain. This facilitates informed decision-making and goal setting.",
      },
      {
        question: "Can Maturity Spectrum 360 be used for continuous improvement, and does it provide predictive insights?",
        answer: "Yes, Maturity Spectrum 360 supports continuous improvement by allowing organizations to track their progress over time and providing predictive insights. These insights help organizations forecast the potential impact of implementing recommendations on their performance metrics.",
      },
    
    
    ],
    accounts: [
      {
        question: "How do I create an account on Maturity Spectrum 360?",
        answer: "To create an account, go to our website at www.maturityspectrum360.com and click on the 'Sign Up' or 'Register' button. You will be prompted to provide your email address, create a password, and complete the registration process. Once registered, you can log in to your account.",
      },
      {
        question: "What if I forget my password or need to reset it?",
        answer: "If you forget your password, click on the 'Forgot Password' or 'Reset Password' link on the login page. You will receive instructions on how to reset your password via email. Follow the provided steps to create a new password and regain access to your account.",
      },
      {
        question: "Can I use the same account for multiple team members within my organization?",
        answer: "Yes, you can. Maturity Spectrum 360 offers collaborative features that allow multiple team members from the same organization to use the same account. Each team member can have their own login credentials while sharing access to assessments, recommendations, and reports.",
      },
      {
        question: "Is my account information and assessment data secure?",
        answer: "We take data security seriously. Your account information and assessment data are encrypted and stored securely. We follow industry best practices to protect your information and ensure confidentiality. You can learn more about our security measures in our Privacy Policy.",
      },
      {
        question: "Can I upgrade my account to access additional features or modules?",
        answer: "Absolutely! Depending on your organization's needs, you can upgrade your account to access more features, additional Center of Excellence (CoE) modules, and enhanced capabilities. Visit the 'License Plans' section of your account to explore and upgrade your plan.",
      },
      
    ],
    pricingLicense: [
      {
        question: "What are the available pricing plans for Maturity Spectrum 360?",
        answer: (
          <div>
          <p className="max-w-3xl pl-5  text-gray-300">
          Maturity Spectrum 360 offers four pricing plans:
              </p>
          <ul className="list-disc pl-12 pt-1">
            <li>Basic</li>
            <li>Professional</li>
            <li>Premium</li>
            <li>Enterprise</li>
          </ul></div>
        ),
      },
      {
        question: "What's included in the Basic plan?",
        answer:  (
          <div>
          <p className="max-w-3xl pl-5 text-gray-300">
          The Basic plan includes:
              </p>
          <ul className="list-disc pl-12 pt-1">
            <li>Access to one CoE module (e.g., DevOps)</li>
            <li>Basic AI assessment capabilities</li>
            <li>Integration with one third-party tool (e.g., JIRA)</li>
            <li>Basic reporting and analytics</li>
            <li>Email support with a 48-hour response time</li>
          </ul></div>
        ),
      },
      {
        question: "What additional features does the Professional plan offer?",
        answer:  (
          <div>
          <p className="max-w-3xl pl-5 text-gray-300">
          The Professional plan includes:
              </p>
          <ul className="list-disc pl-12 pt-1">
            <li>Access to three CoE modules</li>
            <li>Enhanced AI assessment capabilities</li>
            <li>Integration with up to three third-party tools</li>
            <li>Advanced reporting and analytics</li>
            <li>Role-Based Access Control (RBAC)</li>
          </ul></div>
        ),
      },
      {
        question: "Can you tell me about the Premium plan's features?",
        answer: (
          <div>
          <p className="max-w-3xl pl-5 text-gray-300">
          The Premium plan includes:
              </p>
          <ul className="list-disc pl-12 pt-1">
            <li>Access to all available CoE modules</li>
            <li>Premium AI assessment capabilities with in-depth insights</li>
            <li>Unlimited integration with third-party tools</li>
            <li>Dedicated cloud server for optimal performance and data security</li>
            <li>Comprehensive reporting with predictive insights</li>
          </ul></div>
        ),
      },
      {
        question: "What does the Enterprise plan offer, and how can we get a custom package?",
        answer: (
          <div>
          <p className="max-w-3xl pl-5 text-gray-300">
          The Enterprise plan includes all the features of the Premium plan. To get a custom package, please contact our sales team. The Enterprise plan is tailored to your organization's specific needs, and it may include:
              </p>
          <ul className="list-disc pl-12 pt-1">
            <li>A dedicated support team</li>
            <li>Custom integrations</li>
            <li>On-site training and workshops</li>
            <li>Custom development and feature additions</li>
            <li>Expert Assessment and Implementation</li>
          </ul></div>
        ),
      },
    ],
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  
  return (
    <div className=' bg-[#0f172a]'>

      {/* <!-- FAQ --> */}
<div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 lg:pb-5 mx-auto">
  {/* <!-- Title --> */}
  <div class="max-w-2xl mx-auto text-center mb-10 lg:mb-0">
    <h2 class="text-2xl font-semibold tracking-wide underline decoration-[#2563eb] underline-offset-8 md:text-4xl md:leading-tight text-white">Frequently Asked Questions</h2>
    <p class="mt-5 text-gray-300 lg:text-lg">Answers to the most frequently asked questions.</p>
  </div>
  {/* <!-- End Title --> */}
  <div className=' flex justify-center py-10'>
  <ul className="flex max-sm:flex-col gap-4 w-max  rounded-md">
  {tabs.map((tab) => (
    <li
      key={tab.id}
      onClick={() => handleTabClick(tab.id)}
      className={`flex flex-col justify-center w-48 items-center border-2 ${
        activeTab === tab.id ? 'border-blue-600' : 'hover:border-blue-400'
      } rounded-md bg-[#0f172a] text-[16px] font-semibold ${
        activeTab === tab.id ? 'text-blue-300' : 'text-gray-300 hover:text-blue-300'
      } py-4 px-+2 min-w-[120px] cursor-pointer transition-all`}
    >
  
      {tab.id === 'gettingStarted' && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
</svg>
}
      {tab.id === 'assessmentSupport' && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75" />
</svg>
}
      {tab.id === 'accounts' && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 ">
  <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
</svg>
}
      {tab.id === 'pricingLicense' && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
</svg>
}

      {tab.title}
    </li>
  ))}
</ul></div>
  <div class="max-w-6xl mx-auto bg-[#0f172a]">

  <div className="max-w-6xl mx-auto px-4 pt-1">
  <div className="mx-auto w-full max-w-7xl rounded-2xl lg:mb-10  bg-[#111827] p-2">

  {activeTab === 'gettingStarted' && (
      <div>
        {tabContent.gettingStarted.map((qa, index) => (
          <Disclosure key={index}>
           {({ open }) => (
                <>
                  <Disclosure.Button
                    className="flex w-full justify-between rounded-lg bg-[#0f172a] px-4 py-2 text-left text-base lg:text-xl font-normal text-white hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                  >
                    <span>{qa.question}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-5 w-5 text-[#2563eb]`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pb-2 pt-4 text-base lg:text-[17px] text-gray-300">
                    {qa.answer}
                  </Disclosure.Panel>
                  <div className="mb-4"></div>
                </>
              )}
          </Disclosure>
        ))}
      </div>
    )}

{activeTab === 'assessmentSupport' && (
      <div>
        {tabContent.assessmentSupport.map((qa, index) => (
          <Disclosure key={index}>
          {({ open }) => (
                <>
                  <Disclosure.Button
                    className="flex w-full justify-between rounded-lg bg-[#0f172a] px-4 py-2 text-left text-base lg:text-xl font-normal text-white hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                  >
                    <span>{qa.question}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-5 w-5 text-[#2563eb]`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pb-2 pt-4 text-base lg:text-[17px] text-gray-300">
                    {qa.answer}
                  </Disclosure.Panel>
                  <div className="mb-4"></div>
                </>
              )}
          </Disclosure>
        ))}
      </div>
    )}

{activeTab === 'accounts' && (
      <div>
        {tabContent.accounts.map((qa, index) => (
          <Disclosure key={index}>
           {({ open }) => (
                <>
                  <Disclosure.Button
                    className="flex w-full justify-between rounded-lg bg-[#0f172a] px-4 py-2 text-left text-base lg:text-xl font-normal text-white hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                  >
                    <span>{qa.question}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-5 w-5 text-[#2563eb]`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pb-2 pt-4 text-base lg:text-[17px] text-gray-300">
                    {qa.answer}
                  </Disclosure.Panel>
                  <div className="mb-4"></div>
                </>
              )}
          </Disclosure>
        ))}
      </div>
    )}

    
{activeTab === 'pricingLicense' && (
      <div>
        {tabContent.pricingLicense.map((qa, index) => (
          <Disclosure key={index}>
            {({ open }) => (
                <>
                  <Disclosure.Button
                    className="flex w-full justify-between rounded-lg bg-[#0f172a] px-4 py-2 text-left text-base lg:text-xl font-normal text-white hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                  >
                    <span>{qa.question}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-5 w-5 text-[#2563eb]`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pb-2 pt-4 text-base lg:text-[17px] text-gray-300">
                    {qa.answer}
                  </Disclosure.Panel>
                  <div className="mb-4"></div>
                </>
              )}
          </Disclosure>
        ))}
      </div>
    )}
        
        </div>
    </div>
  </div>
</div>

    </div>
  )
}
