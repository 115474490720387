import React from 'react'
import img from '../../assets/img/Features/anim2.gif'
export default function Section1() {
  return (
    <div class="py-16 bg-slate-900 overflow-hidden lg:py-5">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
   
  
      <div class="relative">
        <h2 class="text-center text-3xl underline decoration-[#2563eb] underline-offset-8 leading-8 font-semibold tracking-wide text-white lg:text-4xl">
          Features</h2>
       
      </div>
  
      <div class="relative mt-12 lg:mt-10 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div class="relative">
          <h3 class="text-xl lg:text-4xl font-bold text-white tracking-tight ">Advanced AI Assessment</h3>
         
  
          <dl class="mt-10 space-y-10">
            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  {/* <!-- Heroicon name: outline/globe-alt --> */}
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                  
                </div>
                <p class="ml-16 text-lg lg:text-xl leading-6 font-normal text-white">Powered by state-of-the-art AI algorithms, our platform assesses your organization's maturity and provides actionable insights.</p>
              </dt>
            
            </div>
  
            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  {/* <!-- Heroicon name: outline/scale --> */}
                  <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                  </svg>
                </div>
                <p class="ml-16 text-lg  lg:text-xl leading-6 font-normal text-white">AI-driven insights provide data-backed recommendations that prioritize areas for improvement, enabling informed decision-making and targeted actions</p>
              </dt>
           
            </div>
  
         
          </dl>
        </div>
  
        <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
      
          <img class="relative mx-auto" width="490" src={img} alt=""/>
        </div>
      </div>
  
  

      
    </div>
    
  </div>
  )
}
