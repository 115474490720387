import React from 'react'
import './index.css'
import img1 from '../../assets/img/Homepage/running.png'
import img2 from '../../assets/img/Homepage/high-five.png'
import img3 from '../../assets/img/Homepage/eye.png'
import img4 from '../../assets/img/Homepage/team.png'
import img5 from '../../assets/img/Homepage/deep-learning.png'
import img6 from '../../assets/img/Homepage/teamwork.png'
export default function Section4() {
  return (
    <div>
         <section className="overflow-y-auto bg-[#0f172a]  lg:h-screen scrollbar-hidden">
                <div className="px-8 py-24 mx-auto lg:px-16 max-w-7xl  md:px-12 xl:px-10 lg:flex">
                  <div className="lg:w-1/2">
                    <div className="top-0 pt-8 pb-16 lg:sticky">
                      <div>
                        <div className="lg:pr-24 md:pr-12">
                          <div>
                            <p className="text-2xl font-bold tracking-tight text-white sm:text-4xl">
                            Why Choose <span className='text-transparent bg-clip-text bg-gradient-to-r from-blue-700 via-blue-800 to-gray-100'>
                            Maturity Spectrum 360?
                            </span>
                            </p>
                            
                            <p className="max-w-xl mt-4 text-lg lg:text-xl tracking-tight text-slate-100">
                            In a world where the only constant is change, organizations need a compass that guides them
through the complexities of digital evolution. Maturity Spectrum 360 isn't just a tool; it's a
strategic partner that propels your organization forward.
                            </p>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="lg:w-1/2 ">
                    <div className="flex-shrink-0">
                      <div>
                        <ul className="grid grid-cols-1 gap-12 mt-6 list-none lg:mt-0 lg:gap-24" >
                          <li>
                            <div>
                              <div className="flex items-center justify-center p-2 w-12 h-12  bg-gray-100 rounded-xl">
                                <img src={img1} width={'100%'}></img>
                              </div>
                              <p className="mt-5 text-lg lg:text-xl font-medium leading-6 text-white">
                              Shifting Mindsets: From Static to Dynamic
                              </p>
                            </div>
                            <div className="mt-2 text-base lg:text-lg text-slate-100">
                            Traditional approaches to maturity assessment often result in static reports, highlighting an organization's standing at a specific point in time. However, the digital era demands agility, adaptability, and responsiveness. Maturity Spectrum 360 empowers organizations to adopt a dynamic mindset, encouraging them to continuously assess, evolve, and refine their strategies.
                            </div>
                          </li>
                          <li>
                            <div>
                            <div className="flex items-center justify-center p-2 w-12 h-12  bg-gray-100 rounded-xl">
                                <img src={img2} width={'100%'}></img>
                              </div>
                              <p className="mt-5 text-lg lg:text-xl font-medium leading-6 text-white">
                              Encouraging Participation and Engagement
                              </p>
                            </div>
                            <div className="mt-2 text-base lg:text-lg text-slate-100">
                            A key aspect of building a culture of continuous improvement is fostering engagement at all levels. Maturity Spectrum 360 achieves this through gamification features that turn the assessment process into an engaging and motivating experience. By integrating elements such as points, badges, and leaderboards, the platform encourages healthy competition, boosting user participation and commitment.
                            </div>
                          </li>
                          <li>
                            <div>
                            <div className="flex items-center justify-center p-2 w-12 h-12  bg-gray-100 rounded-xl">
                                <img src={img3} width={'100%'}></img>
                              </div>
                              <p className="mt-5 text-lg lg:text-xl font-medium leading-6 text-white">
                              From Insights to Action: The Power of Recommendations
                              </p>
                            </div>
                            <div className="mt-2 text-base lg:text-lg text-slate-100">
                            It's not enough to identify areas of improvement; true transformation happens when insights lead to actionable steps. Maturity Spectrum 360's AI-powered recommendations bridge the gap between assessment and implementation. The platform's algorithms analyze your organization's maturity data and offer prioritized recommendations tailored to your specific needs. This ensures that improvement efforts are strategic, impactful, and aligned with your growth objectives.
                            </div>
                          </li>
                          <li>
                            <div>
                            <div className="flex items-center justify-center p-2 w-12 h-12  bg-gray-100 rounded-xl">
                                <img src={img4} width={'100%'}></img>
                              </div>
                              <p className="mt-5 text-lg lg:text-xl font-medium leading-6 text-white">
                              Collaboration for Collective Growth
                              </p>
                            </div>
                            <div className="mt-2 text-base lg:text-lg text-slate-100">
                            A culture of continuous improvement thrives on collaboration. Maturity Spectrum 360 facilitates collaboration by allowing teams to work together on assessments and action plans. Cross-functional teams can analyze data, share insights, and collectively work towards elevating the organization's maturity levels. This collaborative approach fosters a sense of ownership and encourages everyone to contribute to the organization's growth.
                            </div>
                          </li>
                          <li>
                            <div>
                            <div className="flex items-center justify-center p-2 w-12 h-12  bg-gray-100 rounded-xl">
                                <img src={img5} width={'100%'}></img>
                              </div>
                              <p className="mt-5 text-lg lg:text-xl font-medium leading-6 text-white">
                              Embracing Learning and Development
                              </p>
                            </div>
                            <div className="mt-2 text-base lg:text-lg text-slate-100">
                            An essential aspect of continuous improvement is a commitment to learning and development. Maturity Spectrum 360 supports this by providing a library of learning resources, from articles to training modules. As users explore these resources, they acquire the knowledge needed to make informed decisions, implement changes, and drive progress.
                            </div>
                          </li>
                          <li>
                            <div>
                            <div className="flex items-center justify-center p-2 w-12 h-12  bg-gray-100 rounded-xl">
                                <img src={img6} width={'100%'}></img>
                              </div>
                              <p className="mt-5 text-lg lg:text-xl font-medium leading-6 text-white">
                              Measuring Progress and Celebrating Wins
                              </p>
                            </div>
                            <div className="mt-2 text-base lg:text-lg text-slate-100">
                            A culture of continuous improvement thrives on measurable progress. Maturity Spectrum 360 offers a timeline view that allows organizations to track their journey, compare scores over time, and visualize their growth. Celebrating milestones and wins not only boosts morale but also reinforces the idea that progress is an ongoing journey, not a one-time destination.
                            </div>
                          </li>
                        
                        
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
    </div>
  )
}
