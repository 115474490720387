import React from "react";
import img1 from "../../assets/img/Homepage/spectrum360.png";
import img2 from "../../assets/img/Homepage/spectra-hub.png";
import img3 from "../../assets/img/Homepage/app-store.png";
import img4 from "../../assets/img/Homepage/spectra-insights.png";
const MS360 = ({ forwardedRef }) => {
  return (
    <div ref={forwardedRef} className=" bg-[#0f172a]">
      <div class="mx-auto  max-w-6xl px-4 sm:px-6 lg:px-8 py-8 lg:py-20">
        <h2 class="  text-white text-2xl font-semibold md:text-4xl text-center">
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-700 via-blue-800 to-gray-100">
            Maturity Spectrum 360 Suite <br />
          </span>
          consists of four portals
        </h2>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-16 sm:gap-8 mt-16 sm:mt-24">
          <div class="text-center ">
            <div class="relative w-20 h-20 sm:w-28 sm:h-28 lg:w-32 lg:h-32 mx-auto left-2">
              <div class="z-10 relative w-full h-full bg-white rounded-full border border-gray-300 shadow flex items-center justify-center">
                <img src={img1} className="w-16 lg:w-20"></img>
              </div>
              <div class="absolute inset-0 -translate-x-2 -translate-y-2 bg-blue-500 rounded-full"></div>
            </div>
            <h3 class="font-bold text-xl text-blue-600 md:text-2xl mt-6 sm:mt-10">
              Spectrum 360
            </h3>
            <p class="leading-relaxed text-slate-100 mt-4 lg:text-lg">
              <span className="text-blue-600">Spectrum 360 </span>
              forms the pillar that serves as the nucleus of your organization's
              transformation efforts. It provides a robust framework for
              assessing your current maturity levels, identifying areas of
              improvement, and benchmarking your progress against industry
              standards and peers.
            </p>
            <a
            href="https://spectrum360.maturityspectrum360.com/"
            aria-label=""
            target="_blank"
            className="inline-flex pt-4 items-center font-semibold transition-colors duration-200 text-blue-600 hover:text-blue-300"
          >
            Learn more
          </a>
          </div>
          <div class="text-center">
            <div class="relative w-20 h-20 sm:w-28 sm:h-28 lg:w-32 lg:h-32 mx-auto left-2">
              <div class="z-10 relative w-full h-full bg-white rounded-full border border-gray-300 shadow flex items-center justify-center">
                <img src={img3} className="w-16 lg:w-20"></img>
              </div>
              <div class="absolute inset-0 -translate-x-2 -translate-y-2 bg-blue-500 rounded-full"></div>
            </div>
            <h3 class="font-bold text-xl text-blue-600 md:text-2xl mt-6 sm:mt-10">
              Spectra Store
            </h3>
            <p class="leading-relaxed text-slate-100 mt-4 lg:text-lg">
              <span className="text-blue-600">Spectra Store </span>
              invites organizations with proven methodologies and success
              stories to publish their maturity frameworks. This unique
              opportunity allows you to showcase your expertise, contribute to
              the broader business and technology communities, and establish
              your organization as a thought leader in your practice area.
            </p>
            <a
            href="https://spectrastore.maturityspectrum360.com/"
            aria-label=""
            target="_blank"
            className="inline-flex pt-4 items-center font-semibold transition-colors duration-200 text-blue-600 hover:text-blue-300"
          >
            Learn more
          </a>
          </div>
          <div class="text-center">
            <div class="relative w-20 h-20 sm:w-28 sm:h-28 lg:w-32 lg:h-32 mx-auto left-2">
              <div class="z-10 relative w-full h-full bg-white rounded-full border border-gray-300 shadow flex items-center justify-center">
                <img src={img2} className="w-16 lg:w-20"></img>
              </div>
              <div class="absolute inset-0 -translate-x-2 -translate-y-2 bg-blue-500 rounded-full"></div>
            </div>
            <h3 class="font-bold text-xl text-blue-600 md:text-2xl mt-6 sm:mt-10">
              Spectra Hub
            </h3>
            <p class="leading-relaxed text-slate-100 mt-4 lg:text-lg">
              <span className="text-blue-600">Spectra Hub </span>
              offers a unique opportunity for assessment service providers and
              consultants to showcase their expertise to a wide audience, engage
              with potential clients, and contribute to a thriving community
              dedicated to organizational transformation.
            </p>
            <a
            href="https://spectrahub.maturityspectrum360.com/"
            aria-label=""
            target="_blank"
            className="inline-flex pt-5 items-center font-semibold transition-colors duration-200 text-blue-600 hover:text-blue-300"
          >
            Learn more
          </a>
          </div>
          <div class="text-center">
            <div class="relative w-20 h-20 sm:w-28 sm:h-28 lg:w-32 lg:h-32 mx-auto left-2">
              <div class="z-10 relative w-full h-full bg-white rounded-full border border-gray-300 shadow flex items-center justify-center">
                <img src={img4} className="w-16 lg:w-20"></img>
              </div>
              <div class="absolute inset-0 -translate-x-2 -translate-y-2 bg-blue-500 rounded-full"></div>
            </div>
            <h3 class="font-bold text-xl text-blue-600 md:text-2xl mt-6 sm:mt-10">
              Spectra Insight
            </h3>
            <p class="leading-relaxed text-slate-100 mt-4 lg:text-lg">
              <span className="text-blue-600">Spectra Insight </span>
              stands at the core of the Maturity Spectrum 360 ecosystem,
              embodying the most advanced AI capabilities to guide organizations
              through their maturity and transformation journeys. Spectra
              Insight provides tailored recommendations across various practice
              areas, including business operations, technology adoption, and
              digital transformation.
            </p>
            <a
            href="https://spectrainsight.maturityspectrum360.com/"
            aria-label=""
            target="_blank"
            className="inline-flex pt-5 items-center font-semibold transition-colors duration-200 text-blue-600 hover:text-blue-300"
          >
            Learn more
          </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MS360;
