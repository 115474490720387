import React from 'react'
import img3 from '../../assets/img/Homepage/346.jpg'
import img2 from '../../assets/img/Homepage/2148230781.jpg'
import img1 from '../../assets/img/Homepage/1975.jpg'
export default function Emerging() {
  return (
    <div className='emerging bg-[#0f172a]'>
 <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
     
      <div className="grid gap-6 row-gap-5 mb-8 lg:grid-cols-3 sm:row-gap-6 sm:grid-cols-2">
        <a  aria-label="View Item">
          <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
            <img
              className="object-cover w-full h-72 md:h-64 xl:h-96"
              src={img1}
              alt=""
            />
            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
              <p className="mb-4 text-lg font-bold text-gray-100">Emerging Startups</p>
              <p className="text-sm md:text-lg tracking-wide text-gray-300">
              Focused on establishing a foundation in best practices across technology and business processes. Key metrics for them would include basic maturity scores, with a strong emphasis on recommendations for quick wins in improving their operations.
              </p>
            </div>
          </div>
        </a>
        
        <a  aria-label="View Item">
          <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
            <img
              className="object-cover w-full h-72 md:h-64 xl:h-96"
              src={img2}
              alt=""
            />
            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
              <p className="mb-4 text-lg font-bold text-gray-100">Growth-Stage Companies</p>
              <p className="text-sm md:text-lg tracking-wide text-gray-300">
              Looking to optimize existing processes and scale operations efficiently. These organizations benefit from industry benchmarking, leading and lagging indicators within their practice areas, and integration capabilities for tracking improvements.
              </p>
            </div>
          </div>
        </a>
        <a  aria-label="View Item">
          <div className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
            <img
              className="object-cover w-full h-72 md:h-64 xl:h-96"
              src={img3}
              alt=""
            />
            <div className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
              <p className="mb-4 text-lg font-bold text-gray-100">
              Established Enterprises
              </p>
              <p className="text-sm md:text-lg tracking-wide text-gray-300">
              Seeking to maintain market leadership through innovation and continuous improvement. They require comprehensive metrics, including advanced maturity assessments, competitive benchmarking, and detailed recommendations with a strategic roadmap.
              </p>
            </div>
          </div>
        </a>
      </div>
    
    </div>
        </div>
  )
}
