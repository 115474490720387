import { React, useState, useRef } from "react";
import Modal from "../../pages/Modal/Modal";

import hero from "../../assets/img/_b55687f0-cf43-4e8b-b82c-e7764cc26e88 (1).png";
import Section1 from "./Section1";
import MS360 from "./MS360";
export default function HeroSection3() {
  const [isModalOpen, setModalOpen] = useState(false);
  const ms360Ref = useRef(null);
  // Modal
  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  const scrollToMS360 = () => {
    ms360Ref.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <section className="py-12 lg:py-2  bg-[#0f172a]  lg:pb-10">
        <div className="px-4 mx-auto md:px-6 lg:px-10 max-w-4xl lg:max-w-7xl">
          <div className="relative flex flex-col-reverse lg:flex-row lg:flex lg:items-center">
            <div className="lg:w-2/4 md:w-2/5 lg:pt-0">
              {/* <p className="text-sm font-normal tracking-widest text-gray-300 uppercase">A Hub for Designers, Developers & Marketers</p> */}
              <h1 className="mt-6  text-4xl font-normal  text-white sm:mt-5 md:text-4xl lg:text-6xl xl:text-7xl ">
                <span className=" text-[#36caee]">Your Digital Compass </span>{" "}
                for Transformation
              </h1>
              <p className="max-w-xl mt-4 text-lg font-normal md:text-base lg:text-lg text-slate-100 sm:mt-8">
                In the ever-evolving landscape of digital and business
                transformation, navigating the path to organizational excellence
                can be challenging. Maturity Spectrum 360 emerges as your
                indispensable partner in this journey, acting as a digital
                compass to guide your strategic direction. Maturity Spectrum 360
                is a tool suite, which in turn consists of 4 portals Spectrum
                360, Spectra Hub , Spectra Store and Spectra Insight. Powered by cutting-edge AI
                technology, our platform offers a holistic approach to
                assessing, benchmarking, and enhancing your organization's
                maturity across various dimensions.
              </p>
              <div className="flex gap-5 ">
                <div className="relative inline-flex items-center justify-center mt-8 sm:mt-12 group">
                  <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>{" "}
                  <a
                    onClick={scrollToMS360}
                    className="relative inline-flex items-center justify-center px-4 py-3 lg:px-8 md:px-8 lg:py-3 md:py-3 text-base font-normal text-white bg-black border border-transparent rounded-full"
                    role="button"
                  >
                    {" "}
                    Start Exploring{" "}
                  </a>
                </div>

                <div className="relative inline-flex items-center justify-center mt-8 sm:mt-12 group">
                  <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
                  <a
                    onClick={openModal}
                    href="#"
                    title=""
                    className="relative inline-flex items-center justify-center px-8 py-3 text-base font-normal text-white bg-slate-900 border border-transparent rounded-full"
                    role="button"
                  >
                    {" "}
                    Register{" "}
                  </a>
                </div>
              </div>
              <div>
                <div className="inline-flex items-center pt-6 mt-8  sm:pt-10 sm:mt-14">
                  <svg
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke-width="1.5"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 7.00003H21M21 7.00003V15M21 7.00003L13 15L9 11L3 17"
                      stroke="url(#a)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="a"
                        x1="3"
                        y1="7.00003"
                        x2="22.2956"
                        y2="12.0274"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop
                          offset="0%"
                          style={{ stopColor: "rgb(6 182 212 / 0)" }}
                        />
                        <stop
                          offset="100%"
                          style={{ stopColor: "rgb(6 182 212 / 0)" }}
                        />
                      </linearGradient>
                    </defs>
                  </svg>

                  {/* <span className="ml-2 text-base font-normal text-white"> 42 new design inspiration was added last week </span> */}
                </div>
              </div>
            </div>

            <div className="mt-8 md:absolute md:mt-0 md:top-10 lg:mt-0  lg:top-0  md:right-0">
              <img
                className="w-full vert-move max-w-xs mx-auto md:max-w-[450px] lg:max-w-[550px] xl:max-w-[600px] "
                src={hero}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <Section1 />
      <MS360 forwardedRef={ms360Ref} />
      {/* Modal */}
      <Modal isOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
}
