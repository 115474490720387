import React from 'react'
import img1 from '../../assets/img/Homepage/44009.jpg'
import img2 from '../../assets/img/Homepage/792.jpg'
import img3 from '../../assets/img/Homepage/2386.jpg'
import img4 from '../../assets/img/Homepage/23089.jpg'
export default function TargetOrganization() {
  return (
    <div>
      <section className="bg-[#0f172a]">
            <div className="container px-6 py-10 mx-auto">
            <h2 class="text-xl font-bold text-center lg:text-5xl mb-4 text-white">
             Target{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-700 via-blue-800 to-gray-100">
              Organization
              </span>
             
            </h2>

                <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 lg:grid-cols-2">
                    <div className="flex items-end overflow-hidden bg-cover rounded-lg h-96" style={{backgroundImage: `url(${img1})`}}>
                        <div className="w-full px-8 py-4 overflow-hidden rounded-b-lg backdrop-blur-sm bg-gray-800/60">
                            <h2 className="mt-4 text-xl lg:text-2xl font-semibold capitalize text-white">Size</h2>
                            <p className="mt-2 text-sm md:text-lg tracking-wider text-gray-300 ">Medium to Large Enterprises.</p>
                        </div>
                    </div>

                    <div className="flex items-end overflow-hidden bg-cover rounded-lg h-96" style={{backgroundImage: `url(${img2})`}}>
                        <div className="w-full px-8 py-1 overflow-hidden rounded-b-lg backdrop-blur-sm  bg-gray-800/60">
                            <h2 className="mt-4 text-xl lg:text-2xl  font-semibold  capitalize text-white">Industry</h2>
                            <p className="mt-2 text-sm md:text-lg  tracking-wider text-gray-300 ">Technology, Healthcare, Finance, Manufacturing, and others undergoing digital transformation.</p>
                        </div>
                    </div>

                    <div className="flex items-end overflow-hidden bg-cover rounded-lg h-96" style={{backgroundImage: `url(${img3})`}}>
                        <div className="w-full px-8 py-1 overflow-hidden rounded-b-lg backdrop-blur-sm bg-gray-800/60">
                            <h2 className="mt-4 text-xl lg:text-2xl  font-semibold capitalize text-white">Need</h2>
                            <p className="mt-2 text-sm md:text-lg  tracking-wider text-gray-300 ">Seeking comprehensive assessment to understand their current maturity level, identify gaps in their practices, and benchmark against industry standards.</p>
                        </div>
                    </div>

                    <div className="flex items-end overflow-hidden bg-cover rounded-lg h-96" style={{backgroundImage:  `url(${img4})`}}>
                        <div className="w-full px-8 py-4 overflow-hidden rounded-b-lg backdrop-blur-sm  bg-gray-800/60">
                            <h2 className="mt-4 text-xl lg:text-2xl  font-semibold  capitalize text-white">Goal</h2>
                            <p className="mt-2 text-sm md:text-lg  tracking-wider text-gray-300 ">To improve operational efficiency, innovate faster, and enhance customer satisfaction through guided transformation strategies.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}
