import React from "react";
import embrace_img from "../../assets/img/teamwork-power-successful-business-meeting-workplace-concept.jpg";

const EmbraceYourPath = () => {
  return (
    <section className="py-12 lg:py-2  bg-[#0f172a] sm:pb-16 lg:pb-10">
      <div className="text-center mt-10 px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-10 lg:flex-row">
        <div className="max-w-xl mb-2 md:mx-auto sm:text-center lg:max-w-4xl ">
          <div>
            <p className="inline-block px-4 py-4 mb-8 text-xs lg:text-lg font-bold tracking-wider text-white uppercase rounded-full bg-blue-600">
              <h2>Embrace Your Path to Excellence</h2>
            </p>
          </div>
          <h2 className=" max-w-full  lg:text-2xl text-base font-normal leading-5 tracking-tight text-slate-100 sm:text-xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="34f481be-159a-4846-821d-9ca19fb6bcc5"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#34f481be-159a-4846-821d-9ca19fb6bcc5)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative "> Maturity Spectrum 360</span>
            </span>{" "}
             is more than a platform; it's a strategic
            partner in your quest for organizational excellence. By providing a
            comprehensive view of your maturity landscape, actionable insights,
            and a clear roadmap for improvement, we empower you to navigate your
            transformation journey with confidence. Discover where you stand,
            identify your opportunities for growth, and embark on your path to
            excellence with Maturity Spectrum 360: Your Digital Compass for
            Transformation.
          </h2>
        </div>
      </div>
     
    </section>
  );
};

export default EmbraceYourPath;
