import React from "react";
import { Link } from "react-router-dom";
import "../../assets/Homepage.css";
import img1 from "../../assets/img/Homepage/compass.jpg";
import img2 from "../../assets/img/Homepage/crafted.jpg";
import img3 from "../../assets/img/Homepage/gaps.jpg";
import img4 from "../../assets/img/Homepage/contnuous.jpg";
import img5 from "../../assets/img/Homepage/businesswoman-using-tablet-analysis-graph-company-finance-strategy-statistics-success-concept-planning-future-office-room.jpg";

export default function Section3() {
  return (
    <div className="bg-[#0f172a]  px-4 py-16 lg:px-20    lg:py-20">
      <div className=" flex flex-wrap justify-center gap-8 ">
        <article class="card w-[650px]">
          <img class="card__background" src={img1} alt="" />
          <div class="card__content | flow">
            <div class="card__content--container | flow ">
              <h2 class="card__title text-white text-2xl lg:text-4xl font-semibold">
                Compass towards Growth
              </h2>
              <p class="card__description lg:text-lg text-white">
                In an era where digital transformation is not just a buzzword
                but a strategic imperative, understanding your organization's
                maturity level is the key to unlocking unparalleled growth.
                Maturity Spectrum 360 is the compass that guides you on the
                digital transformation journey.
              </p>
            </div>
            <Link to="/features">
              <button class="card__button bg-[#2563eb] text-white ">
                Read more
              </button>
            </Link>
          </div>
        </article>

        <article class="card w-[650px]">
          <img class="card__background" src={img2} alt="" />
          <div class="card__content | flow">
            <div class="card__content--container | flow">
              <h2 class="card__title text-white text-2xl lg:text-4xl font-semibold">
                Crafted by AeroAegis
              </h2>
              <p class="card__description lg:text-lg text-white">
                Why settle for generic when you can have a product designed by
                experts? Maturity Spectrum 360 is a testament to AeroAegis's
                commitment to excellence, innovation, and delivering
                unparalleled value. With deep-rooted expertise in the IT
                landscape and a history of creating groundbreaking solutions,
                AeroAegis brings you a product that truly understands your
                needs.
              </p>
            </div>
            <a href="https://aeroaegis.com/" target="_blank">
              <button class="card__button bg-[#2563eb] text-white">
                Read more
              </button>
            </a>
          </div>
        </article>
        <article class="card w-[650px]">
          <img class="card__background" src={img3} alt="" />
          <div class="card__content | flow">
            <div class="card__content--container | flow">
              <h2 class="card__title text-white text-2xl lg:text-4xl font-semibold mb-12">
                Identifying Gaps & Establishing a Roadmap
              </h2>
              <p class="card__description lg:text-lg text-white">
                With Maturity Spectrum 360, you not only get a clear picture of
                where you stand, but you also get a roadmap crafted specifically
                for your journey ahead. Our platform's recommendations, backed
                by AI-driven insights, ensure that you identify gaps in your
                processes and strategies. Once these gaps are identified, the
                platform provides actionable steps and a clear roadmap to bridge
                these gaps, ensuring continuous growth and maturity.
              </p>
            </div>
            <Link to="/features">
              {" "}
              <button class="card__button bg-[#2563eb] text-white">
                Read more
              </button>
            </Link>
          </div>
        </article>

        <article class="card w-[650px]">
          <img class="card__background" src={img4} alt="" />
          <div class="card__content | flow">
            <div class="card__content--container | flow">
              <h2 class="card__title text-white  text-2xl lg:text-4xl font-semibold">
                Building a Culture of Continuous Improvement
              </h2>
              <p class="card__description lg:text-lg text-white">
                In the dynamic landscape of today's business world, staying
                ahead of the curve is not just a goal; it's a necessity.
                Organizations striving for excellence understand that the
                journey of digital transformation is an ongoing process,
                requiring continuous learning, adaptation, and improvement. The
                foundation of a successful digital transformation lies in
                building a culture of continuous improvement, and Maturity
                Spectrum 360 is the catalyst that makes this culture flourish.
              </p>
            </div>
            <Link to="/features">
              {" "}
              <button class="card__button bg-[#2563eb] text-white">
                Read more
              </button>
            </Link>
          </div>
        </article>

        <article class="card w-[650px]">
          <img class="card__background" src={img5} alt="" />
          <div class="card__content | flow">
            <div class="card__content--container | flow">
              <h2 class="card__title text-white  text-2xl lg:text-4xl font-semibold">
                Benchmarking Against Industry Standards
              </h2>
              <p class="card__description lg:text-lg text-white">
                Understanding your position in the competitive landscape is
                crucial. Maturity Spectrum 360 benchmarks your organization
                against industry standards and peers, offering insights into
                your standing as a leader, follower, or laggard. This
                comparative analysis helps identify strengths to capitalize on
                and weaknesses to address, providing a solid foundation for
                strategic planning and investment decisions.
              </p>
            </div>
            <Link to="/features">
              {" "}
              <button class="card__button bg-[#2563eb] text-white">
                Read more
              </button>
            </Link>
          </div>
        </article>
      </div>
    </div>
  );
}
