import { React, useState, useEffect } from "react";
import Modal from "../../pages/Modal/Modal";
import checkgrey_img from '../../assets/img/check-grey.svg'
import checkwhite_img from '../../assets/img/check-white.svg'
import arrow_img from '../../assets/img/arrow-right.svg'
import img1 from '../../assets/img/abstract-1.jpg'
import img2 from '../../assets/img/abstract-2.jpg'
import img3 from '../../assets/img/abstract-3.jpg'
import img4 from '../../assets/img/abstract-4.jpg'

export default function Index() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isAnnual, setIsAnnual] = useState(true);

  // Modal

  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <div class="lg:py-5   text-white bg-[#0f172a]">
        <main class="max-w-8xl mx-auto pt-10 pb-28 px-8">
          <div class="max-w-xl mx-auto mb-14 text-center">
            <h1 class="text-4xl font-semibold mb-6 lg:text-5xl">Pricing</h1>
            <p class="text-xl text-gray-400 font-medium">
              Choose a plan that works best for you.
            </p>
          </div>

          {/* Pricing toggle */}
          <div className="flex justify-center max-w-[14rem] m-auto mb-8 lg:mb-16">
            <div className="relative flex w-full p-1 bg-white  rounded-full">
              <span
                className="absolute inset-0 m-1 pointer-events-none"
                aria-hidden="true"
              >
                <span
                  className={`absolute inset-0 w-1/2 bg-indigo-500 rounded-full shadow-sm shadow-indigo-950/10 transform transition-transform duration-150 ease-in-out ${
                    isAnnual ? "translate-x-0" : "translate-x-full"
                  }`}
                ></span>
              </span>

              <button
                className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${
                  isAnnual ? "text-white  " : "text-slate-500"
                }`}
                onClick={() => setIsAnnual(true)}
                aria-pressed={isAnnual}
              >
                Monthly
              </button>
              <button
                className={`relative flex-1 text-sm font-medium h-8 rounded-full focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150 ease-in-out ${
                  isAnnual ? "text-slate-500" : "text-white "
                }`}
                onClick={() => setIsAnnual(false)}
                aria-pressed={isAnnual}
              >
                Yearly{" "}
                <span
                  className={`${isAnnual ? "text-indigo-300" : "text-slate-300 "}`}
                >
                  -20%
                </span>
              </button>
            </div>
          </div>

          <div class="flex  flex-col justify-between items-center  lg:flex-row lg:items-start">
            <div class="w-full flex-1 mt-8 p-8 order-1 bg-white shadow-xl rounded-3xl sm:w-96 lg:w-full lg:order-1 lg:rounded-r-none">
              <div class="mb-7 pb-7 flex items-center border-b border-gray-300">
                <img
                  src={img1}
                  alt=""
                  class="rounded-3xl w-20 h-20"
                />
                <div class="ml-5">
                  <span class="block text-lg lg:text-2xl text-black font-semibold">
                    Basic
                  </span>
                  <span>
                    <span class="font-medium text-gray-500 text-xl align-top">
                      $&thinsp;
                    </span>
                    <span class="text-lg lg:text-3xl text-black font-bold">
                      {isAnnual ? "499" : "4999"}{" "}
                    </span>
                  </span>
                  <span class="text-gray-500 font-medium">
                    {isAnnual ? "/ month" : "/ year"}
                  </span>
                </div>
              </div>
              <ul class="mb-7 font-medium text-gray-500">
                <li class="flex text-lg mb-2">
                  <img src={checkgrey_img} />
                  <span class="ml-3">
                    Access to one CoE module (e.g., DevOps){" "}
                  </span>
                </li>
                <li class="flex text-lg mb-2">
                  <img src={checkgrey_img} />
                  <span class="ml-3">Basic AI assessment capabilities</span>
                </li>
                <li class="flex text-lg mb-2">
                  <img src={checkgrey_img} />
                  <span class="ml-3">
                    Integration with one third-party tool (e.g., JIRA)
                  </span>
                </li>
                <li class="flex text-lg mb-2">
                  <img src={checkgrey_img} />
                  <span class="ml-3">Basic reporting and analytics</span>
                </li>
                <li class="flex text-lg lg:mb-32">
                  <img src={checkgrey_img} />
                  <span class="ml-3">
                    Email support with a 48-hour response time
                  </span>
                </li>
              </ul>
              <a
               onClick={openModal}
                class="flex justify-center items-center bg-indigo-600 hover:bg-indigo-500 rounded-xl py-5 px-4 text-center text-white text-xl"
              >
               Subscribe
                <img
                  src={arrow_img}
                  class="ml-2"
                />
              </a>
            </div>

            <div class="w-full flex-1 mt-8 p-8 order-2 shadow-xl rounded-3xl bg-white text-gray-400 sm:w-96 lg:w-full lg:order-2 lg:mt-0">
              <div class="mb-8 pb-8 flex items-center border-b border-gray-300">
                <img
                  src={img2}
                  alt=""
                  class="rounded-3xl w-20 h-20"
                />
                <div class="ml-5">
                  <span class="block text-lg lg:text-3xl font-semibold text-black">
                    Professional
                  </span>
                  <span>
                    <span class="font-medium text-gray-500 text-xl align-top">
                      $&thinsp;
                    </span>
                    <span class="text-lg lg:text-3xl font-bold text-black">
                      {isAnnual ? "999" : "9999"}{" "}
                    </span>
                  </span>
                  <span class="font-medium">
                    {isAnnual ? "/ month" : "/ year"}
                  </span>
                </div>
              </div>
              <ul class="mb-10 font-medium text-xl text-gray-500">
                <li class="flex mb-6">
                  <img src={checkgrey_img} />
                  <span class="ml-3">Access to three CoE modules</span>
                </li>
                <li class="flex mb-6">
                  <img src={checkgrey_img} />
                  <span class="ml-3">Enhanced AI assessment capabilities</span>
                </li>
                <li class="flex mb-6">
                  <img src={checkgrey_img} />
                  <span class="ml-3">
                    Integration with up to three third-party tools
                  </span>
                </li>
                <li class="flex mb-6">
                  <img src={checkgrey_img} />
                  <span class="ml-3">Advanced reporting and analytics</span>
                </li>

                <li class="flex lg:mb-32">
                  <img src={checkgrey_img} />
                  <span class="ml-3">Role-Based Access Control (RBAC)</span>
                </li>
              </ul>
              <a
                onClick={openModal}
                class="flex justify-center items-center bg-indigo-600 hover:bg-indigo-500 rounded-xl py-6 px-4 text-center text-white text-2xl"
              >
               Subscribe
                <img
                  src={arrow_img}
                  class="ml-2"
                />
              </a>
            </div>

            <div class="w-full flex-1 mt-8 p-8 order-3 shadow-xl rounded-3xl bg-gray-800 text-gray-400 sm:w-96 lg:w-full lg:order-3 lg:mt-0">
              <div class="mb-8 pb-8 flex items-center border-b border-gray-600">
                <img
                  src={img4}
                  alt=""
                  class="rounded-3xl w-20 h-20"
                />
                <div class="ml-5">
                  <span class="block text-lg lg:text-3xl font-semibold text-white">
                  Premium
                  </span>
                  <span>
                    <span class="font-medium text-xl align-top">$&thinsp;</span>
                    <span class="text-lg lg:text-3xl font-bold text-white">
                      {isAnnual ? "1999" : "19999"}{" "}
                    </span>
                  </span>
                  <span class="font-medium">
                    {isAnnual ? "/ month" : "/ year"}
                  </span>
                </div>
              </div>
              <ul class="mb-11 font-medium text-white text-xl">
                <li class="flex mb-6">
                  <img src={checkwhite_img} />
                  <span class="ml-3">Access to all available CoE modules</span>
                </li>
                <li class="flex mb-6">
                  <img src={checkwhite_img} />
                  <span class="ml-3">
                    Premium AI assessment capabilities with in-depth insights
                  </span>
                </li>
                <li class="flex mb-6">
                  <img src={checkwhite_img} />
                  <span class="ml-3">
                    Unlimited integration with third-party tools
                  </span>
                </li>
                <li class="flex mb-6">
                  <img src={checkwhite_img} />
                  <span class="ml-3">
                    Dedicated cloud server for optimal performance and data
                    security
                  </span>
                </li>

                <li class="flex">
                  <img src={checkwhite_img} />
                  <span class="ml-3">
                    Comprehensive reporting with predictive insights
                  </span>
                </li>
              </ul>
              <a
               onClick={openModal}
                class="flex justify-center items-center bg-indigo-600 hover:bg-indigo-500 rounded-xl py-6 px-4 text-center text-white text-2xl"
              >
               Subscribe
                <img
                  src={arrow_img}
                  class="ml-2"
                />
              </a>
            </div>

            <div class="w-full flex-1 mt-8 p-8 order-4 bg-white shadow-xl rounded-3xl sm:w-96 lg:w-full lg:order-4 lg:rounded-l-none">
              <div class="mb-7 pb-7 flex items-center border-b border-gray-300">
                <img
                  src={img3}
                  alt=""
                  class="rounded-3xl w-20 h-20"
                />
                <div class="ml-5">
                  <span class="block text-lg lg:text-2xl text-black font-semibold">
                    Enterprise
                  </span>
                  <span>
                    
                    <span class="text-lg lg:text-3xl text-black font-bold">
                   CUSTOM{" "}
                    </span>
                  </span>
                  
                </div>
              </div>
              <ul class="mb-7 font-medium text-gray-500">
                <li class="flex text-lg mb-2">
                  <img src={checkgrey_img} />
                  <span class="ml-3">All features of the Premium plan</span>
                </li>
                <li class="flex text-lg mb-2">
                  <img src={checkgrey_img} />
                  <span class="ml-3">
                    Custom package tailored for the organization's specific
                    needs
                  </span>
                </li>
                <li class="flex text-lg mb-2">
                  <img src={checkgrey_img} />
                  <span class="ml-3">
                    Dedicated support team and custom integrations
                  </span>
                </li>
                <li class="flex text-lg mb-2">
                  <img src={checkgrey_img} />
                  <span class="ml-3">On-site training and workshops</span>
                </li>
                <li class="flex text-lg mb-2">
                  <img src={checkgrey_img} />
                  <span class="ml-3">
                    Custom development and feature additions
                  </span>
                </li>
                <li class="flex text-lg">
                  <img src={checkgrey_img}/>
                  <span class="ml-3">Expert Assessment and Implementation</span>
                </li>
              </ul>
              <a
               onClick={openModal}
                class="flex justify-center items-center bg-indigo-600 hover:bg-indigo-500 rounded-xl py-5 px-4 text-center text-white text-xl"
              >
               Subscribe
                <img
                  src={arrow_img}
                  class="ml-2"
                />
              </a>
            </div>
          </div>
        </main>
      </div>
      {/* Modal */}
      <Modal isOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
}
