import React from "react";
import HeroSection from "./HeroSection";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import HeroSection2 from "./HeroSection2";
import HeroSection3 from "./HeroSection3";
import LastSection from "./LastSection";
import Timeline from "./Timeline";
import Timeline2 from "./Timeline2";
import { Fade, Slide } from "react-awesome-reveal";
import VideoSection from "./VideoSection";
import EmbraceYourPath from "./EmbraceYourPath";
import Comprehensive from "./Comprehensive";
import Personalized from "./Personalized";
import Benchmark from "./Benchmark";
import MS360 from "./MS360";
import Emerging from "./Emerging";
import TargetOrganization from "./TargetOrganization";
export default function index() {
  return (
    <div>
      <Fade duration={500} triggerOnce>
        {/* <HeroSection/> */}
        {/* <HeroSection2/> */}
        <HeroSection3 />
      </Fade>

    
      <Comprehensive />
      <Section4 />
      
      <Section3 />
     
      {/* <Benchmark /> */}
      {/* <Section2/> */}
      {/* <Timeline/> */}
      <Personalized />
      <Emerging/>
      <TargetOrganization/>
    
      <EmbraceYourPath />
    
      {/* <LastSection/> */}
      <VideoSection />
      
    </div>
  );
}
