import React, { useState, useEffect } from 'react'
import Modal from '../../pages/Modal/Modal'

import img from '../../assets/img/Mail sent-rafiki.png'
import img2 from '../../assets/img/Placeholder-rafiki.png'
import img3 from '../../assets/img/Calling-rafiki.png'


export default function Index() {
  const [isModalOpen, setModalOpen] = useState(false);
  // Modal
   
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  

  return (
    <div >
    {/* Modal */}
    <Modal isOpen={isModalOpen} closeModal={closeModal} />
        <section class="bg-[#0f172a] lg:pb-20">
    <div class="container px-6 py-12 lg:py-5 mx-auto">
        <div class="text-center">
            <p class="font-medium lg:text-xl text-blue-400">Contact us</p>

            <h1 class="mt-2 text-2xl font-semibold  md:text-5xl text-white">Get in touch</h1>

            <p class="mt-3 lg:text-lg text-gray-400">Our friendly team is always here to chat.</p>
        </div>

        <div class="grid grid-cols-1 gap-12 mt-10 md:grid-cols-1 lg:grid-cols-3">
            <div class="flex flex-col items-center justify-center text-center">
                <div>
                  <img src={img} width={250}></img>
                </div>

                <h2 class="mt-4 text-lg font-medium  text-white">Email</h2>
                <p class="mt-2  text-gray-400">Our friendly team is here to help.</p>
                <p class="mt-2  text-blue-400">sales@aeroaegis.com</p>
            </div>

            <div class="flex flex-col items-center justify-center text-center">
            <div>
                  <img src={img2} width={250}></img>
                </div>
                
                <h2 class="mt-4 text-lg font-medium   text-white">Start a new case </h2>
                <p class="mt-2  text-gray-400">Just send us your questions
or concerns by starting a
new case and we will give
you the help you need.</p>
               
                <div className=' py-5'>
                <a onClick={openModal}  class="relative cursor-pointer inline-flex items-center justify-start inline-block px-5 py-3 overflow-hidden font-medium transition-all bg-blue-600 rounded-full hover:bg-white group">
<span class="absolute inset-0 border-0 group-hover:border-[25px] ease-linear duration-100 transition-all border-white rounded-full"></span>
<span class="relative w-full text-left text-white transition-colors duration-200 ease-in-out group-hover:text-blue-600">Start Here</span>
</a>

                </div>
            </div>

            <div class="flex flex-col items-center justify-center text-center">
            <div>
                  <img src={img3} width={250}></img>
                </div>
                
                <h2 class="mt-4 text-lg font-medium  text-white">Phone</h2>
                <p class="mt-2  text-gray-400">Mon-Fri from 9am to 7pm.</p>
                <p class="mt-2  text-blue-400">02269718456 (IN)</p>
            </div>
        </div>
    </div>
</section>
      
    </div>
  );
}
