import React from 'react'
import img from '../../assets/img/Features/anim4.gif'
import img2 from '../../assets/img/Features/access.svg'
export default function Section3() {
  return (
    <div class="py-16 bg-slate-900 overflow-hidden lg:py-5">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
  
      <div class="relative mt-12 lg:mt-10 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div class="relative">
          <h3 class="text-xl lg:text-4xl font-bold text-white tracking-tight ">Role-Based Access Control (RBAC)</h3>
         
  
          <dl class="mt-10 space-y-10">
            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  {/* <!-- Heroicon name: outline/globe-alt --> */}
                  <img src={img2} className='h-10 w-10' ></img>
                </div>
                <p class="ml-16 text-lg lg:text-xl leading-6 font-normal text-white">Define roles and responsibilities to ensure that team members have appropriate access to the platform.</p>
              </dt>
          
            </div>
  
          
  
         
          </dl>
        </div>
  
        <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
      
          <img class="relative mx-auto" width="490" src={img} alt=""/>
        </div>
      </div>
  
  

      
    </div>
    
  </div>
  )
}
