import React, { useRef } from "react";

export default function VideoSection() {
  return (
    <div style={{ position: "relative" }}>
      <iframe
        width="100%"
        className="h-[185px] md:h-[435px] lg:h-[855px]"
        src="https://www.youtube.com/embed/Cz7osUOxtpQ?si=lyICuO31GahYIU3H"
        title="Maturity Spectrum 360"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      ></iframe>
    </div>
  );
}
