import React from 'react'
import img from '../../assets/img/Features/anim3.gif'
export default function Section2() {
  return (
    <div class="py-16 bg-slate-900 overflow-hidden lg:py-5">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
    <div class="relative mt-12 sm:mt-16 lg:mt-10">
    <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div class="lg:col-start-2">
        <h3 class="text-xl lg:text-4xl font-bold text-white tracking-tight ">Dedicated Cloud Server</h3>
        {/* <p class="mt-3 text-lg text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit ex obcaecati natus eligendi delectus, cum deleniti sunt in labore nihil quod quibusdam expedita nemo.</p> */}

        <dl class="mt-10 space-y-10">
          <div class="relative">
            <dt>
              <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                {/* <!-- Heroicon name: outline/annotation --> */}
                <svg className=' h-6 w-6' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18 2H6v2h12V2zM4 6h16v2H4V6zm-2 4h20v12H2V10zm18 10v-8H4v8h16z" fill="#ffffff"></path> </g></svg>
              </div>
              <p class="ml-16 text-lg lg:text-xl leading-6 font-normal text-white">Our top-tier plans come with a dedicated server on the cloud, ensuring optimal performance, security, and data isolation.</p>
            </dt>
           
          </div>

         
        </dl>
      </div>

      <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
    
        <img class="relative mx-auto" width="490" src={img} alt=""/>
      </div>
    </div>
  </div>
  </div>
  </div>
  )
}
