import { Fragment, useEffect, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";

import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import "./index.css";
import logo from "../assets/img/ms360-logo-1.svg";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isShowing, setIsShowing] = useState(false)
  // Sticky Header
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const isScrolled = scrollPosition > 50;

      setIsSticky(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerClass = isSticky
    ? "fixed top-0 left-0 w-full  shadow-md z-50  transition-all duration-300 ease-in-out transform translate-y-0 "
    : "";

  return (
    <div>
      <div className={headerClass}>
        <div class="bg-gray-900 ">
          <div class="px-4 py-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
            <div class="relative flex items-center justify-between">
              <a href="/" class="inline-flex items-center lg:mr-48">
                <img src={logo} width={42}></img>
                <span class="ml-2  text-sm lg:text-2xl font-bold  text-gray-100 uppercase">
                  Maturity Spectrum 360
                </span>
              </a>
              <ul class="items-center hidden space-x-8  lg:flex">
                {" "}
                <li>
                  <Menu>
                    <Menu.Button onMouseEnter={() => setIsShowing(true)}
onMouseLeave={() => setIsShowing(false)} className=" font-medium link link-underline link-underline-black uppercase tracking-wide text-gray-100 transition-colors duration-200">
                      Maturity Spectrum 360 Suite
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      show={isShowing}
onMouseEnter={() => setIsShowing(true)}
onMouseLeave={() => setIsShowing(false)}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute  z-30 mt-2 w-60  divide-gray-100 rounded-xl bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                        <div className="px-1 py-1">
                          <Menu.Item>
                            {({ active }) => (
                            <a  href="https://spectrum360.maturityspectrum360.com/"
                            aria-label=""
                            target="_blank"> <button
                                className={`${
                                  active
                                    ? "bg-blue-600  text-white"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                               Spectrum 360
                              </button></a> 
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a  href="https://spectrahub.maturityspectrum360.com/"
                              aria-label=""
                              target="_blank"> <button
                                className={`${
                                  active
                                    ? "bg-blue-600  text-white"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                              Spectra Hub
                              </button></a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                            <a  href="https://spectrastore.maturityspectrum360.com/"
                            aria-label=""
                            target="_blank">  <button
                                className={`${
                                  active
                                    ? "bg-blue-600  text-white"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                Spectra Store
                              </button></a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                             <a  href="https://spectrainsight.maturityspectrum360.com/"
                             aria-label=""
                             target="_blank">   <button
                                className={`${
                                  active
                                    ? "bg-blue-600  text-white"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                Spectra Insight
                              </button></a>
                            )}
                          </Menu.Item>
                        </div>
                       
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </li>
                <Link to="/features">
                  {" "}
                  <li>
                    <a class="font-medium link link-underline link-underline-black uppercase tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400">
                      Features
                    </a>
                  </li>
                </Link>
                <Link to="/faq">
                  {" "}
                  <li>
                    <a class="font-medium  link link-underline link-underline-black tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400">
                      FAQ
                    </a>
                  </li>
                </Link>
                <Link to="/contact">  <li>
                  <button
                   
                    className="inline-flex  items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded-3xl shadow-md bg-blue-600 hover:bg-blue-500 focus:shadow-outline focus:outline-none"
                   
                  >
                    Contact Us
                  </button>
                </li></Link>
              </ul>
            

              <div class="lg:hidden ">
                <button
                  aria-label="Open Menu"
                  title="Open Menu"
                  class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
                  onClick={() => setIsMenuOpen(true)}
                >
                  <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                    />
                    <path
                      fill="currentColor"
                      d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                    />
                    <path
                      fill="currentColor"
                      d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                    />
                  </svg>
                </button>
                {isMenuOpen && (
                  <div class="absolute top-0 left-0 w-full z-10 ">
                    <div class="p-5 bg-gray-900 border border-slate-800 rounded shadow-sm">
                      <div class="flex items-center justify-between mb-4">
                        <div>
                          <a href="/" class="inline-flex items-center">
                            <img src={logo} width={42}></img>
                            <span class="ml-2 text-[14px] lg:text-['20px'] font-bold  text-gray-100 uppercase">
                              Maturity Spectrum 360
                            </span>
                          </a>
                        </div>
                        <div>
                          <button
                            aria-label="Close Menu"
                            title="Close Menu"
                            class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <nav>
                        <ul class="space-y-8 ">
                         
                            {" "}
                            <li>
                            <Menu>
                    <Menu.Button> <a className=" font-normal text-base tracking-wide p-2 text-gray-100 transition-colors duration-200 hover:text-[#2563eb]">
                      Maturity Spectrum 360 Suite</a>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute  z-30 mt-2 w-60  divide-gray-100 rounded-xl bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                        <div className="px-1 py-1">
                          <Menu.Item>
                            {({ active }) => (
                            <a  href="https://spectrum360.maturityspectrum360.com/"
                            aria-label=""
                            target="_blank">  <button
                                className={`${
                                  active
                                    ? "bg-blue-600  text-white"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                               Spectrum 360
                              </button></a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                             <a  href="https://spectrahub.maturityspectrum360.com/"
                             aria-label=""
                             target="_blank">  <button
                                className={`${
                                  active
                                    ? "bg-blue-600  text-white"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                              Spectra Hub
                              </button></a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                             <a  href="https://spectrastore.maturityspectrum360.com/"
                             aria-label=""
                             target="_blank">  <button
                                className={`${
                                  active
                                    ? "bg-blue-600  text-white"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                Spectra Store
                              </button></a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                             <a  href="https://spectrainsight.maturityspectrum360.com/"
                             aria-label=""
                             target="_blank">  <button
                                className={`${
                                  active
                                    ? "bg-blue-600  text-white"
                                    : "text-gray-900"
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                Spectra Insight
                              </button></a>
                            )}
                          </Menu.Item>
                        </div>
                       
                      </Menu.Items>
                    </Transition>
                  </Menu>
                            </li>
                          
                          <Link to="/features">
                            {" "}
                            <li>
                            <a class="font-normal text-base tracking-wide p-2 text-gray-100 transition-colors duration-200 hover:text-[#2563eb]">
                                Features
                              </a>
                            </li>
                          </Link>

                          <Link to="/faq">
                            {" "}
                            <li>
                              <a class="font-normal text-base tracking-wide p-2 text-gray-100 transition-colors duration-200 hover:text-[#2563eb]">
                                FAQ
                              </a>
                            </li>
                          </Link>
                          
                          <Link to="/contact">
                            <li className="pt-5">
                              <a  class="inline-flex items-center justify-center cursor-pointer h-10 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-[#2563eb] hover:bg-blue-400 hover:text-slate-800 focus:shadow-outline focus:outline-none">
                                Contact Us
                              </a>
                            </li></Link>
                         
                        </ul>
                      </nav>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*    
     <Modal isOpen={isModalOpen} closeModal={closeModal} /> */}
    </div>
  );
}
