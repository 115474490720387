import React from 'react'
import img from '../../assets/img/Features/anim6.gif'
export default function Section5() {
  return (
    <div class="py-16 bg-slate-900 overflow-hidden lg:py-5">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
  
      <div class="relative mt-12 lg:mt-10 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div class="relative">
          <h3 class="text-xl lg:text-4xl font-bold text-white tracking-tight ">
In-depth Reporting & Analytics</h3>
          {/* <p class="mt-3 text-lg text-gray-500">Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur minima sequi recusandae, porro maiores officia assumenda aliquam laborum ab aliquid veritatis impedit odit adipisci optio iste blanditiis facere. Totam, velit.</p> */}
  
          <dl class="mt-10 space-y-10">
            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  {/* <!-- Heroicon name: outline/globe-alt --> */}
                  <svg className=' h-6 w-6' xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 36 36"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><rect x="6.48" y="18" width="5.76" height="11.52" rx="1" ry="1"></rect><rect x="15.12" y="6.48" width="5.76" height="23.04" rx="1" ry="1"></rect><rect x="23.76" y="14.16" width="5.76" height="15.36" rx="1" ry="1"></rect></g></svg>
                </div>
                <p class="ml-16 text-lg lg:text-xl leading-6 font-normal text-white">Get comprehensive reports and analytical insights that highlight your strengths, areas of improvement, and how you compare to industry benchmarks.</p>
              </dt>
       
            </div>
  
            
  
            <div class="relative">
              <dt>
                <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  {/* <!-- Heroicon name: outline/lightning-bolt --> */}
                  <svg className=' h-6 w-6' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M9 4.45962C9.91153 4.16968 10.9104 4 12 4C16.1819 4 19.028 6.49956 20.7251 8.70433C21.575 9.80853 22 10.3606 22 12C22 13.6394 21.575 14.1915 20.7251 15.2957C19.028 17.5004 16.1819 20 12 20C7.81811 20 4.97196 17.5004 3.27489 15.2957C2.42496 14.1915 2 13.6394 2 12C2 10.3606 2.42496 9.80853 3.27489 8.70433C3.75612 8.07914 4.32973 7.43025 5 6.82137" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round"></path> <path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#ffffff" stroke-width="1.5"></path> </g></svg>
                </div>
                <p class="ml-16 text-lg lg:text-xl leading-6 font-normal text-white">Organizations gain visibility into strengths, areas for improvement, and how they compare to industry benchmarks. This information forms the basis for strategic decision-making.</p>
              </dt>

            </div>
          </dl>
        </div>
  
        <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
      
          <img class="relative mx-auto" width="490" src={img} alt=""/>
        </div>
      </div>
  
  

      
    </div>
    
  </div>
  )
}
