import React from 'react'
import img1 from '../../assets/img/Homepage/insights.png'
import img2 from '../../assets/img/Homepage/assessment.png'
import img3 from '../../assets/img/Homepage/growth.png'

export default function Section1() {
  return (
    <div>
        <section class="pb-8  bg-slate-900 lg:pb-[50px] overflow-hidden ">
      <div class="container">
        <div class="-mx-4 flex flex-wrap ">
          <div class="w-full px-4">
            <div class="mx-auto mb-12 max-w-[485px]  text-center lg:mb-[70px]">
              {/* <span class="mb-2 block text-lg font-semibold text-white">
                Features
              </span> */}
              <h2
                class="mb-3 text-3xl font-bold text-dark text-white sm:text-4xl md:text-[40px] md:leading-[1.2]"
              >
                Features
              </h2>
            
            </div>
          </div>
        </div>
        <div class=" flex flex-wrap justify-center px-5 md:ml-40  lg:ml-20 ">
          <div class="w-full px-4 md:w-1/3 lg:w-1/4">
            <div class="wow fadeInUp group mb-12" data-wow-delay=".1s">
              <div
                class="relative z-10 mb-10 flex h-[70px] w-[70px] items-center justify-center rounded-[14px] bg-blue-600"
              >
                <span
                  class="absolute left-0 top-0 -z-[1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-[14px] bg-blue-600 bg-opacity-20 duration-300 group-hover:rotate-45"
                ></span>
                <img src={img1} className=' w-10'></img>
              </div>
              <h4 class="mb-3 text-xl font-bold text-white ">
              Actionable Insights
              </h4>
              <p class="mb-8 text-slate-100 lg:text-lg  lg:mb-9">
              Receive tailored recommendations, step-by-step implementation guides, and resources to fuel growth.
              </p>
           
            </div>
          </div>
          <div class="w-full px-4 md:w-1/3 lg:w-1/4">
            <div class="wow fadeInUp group mb-12" data-wow-delay=".15s">
              <div
                class="relative z-10 mb-10 flex h-[70px] w-[70px] items-center justify-center rounded-[14px] bg-blue-600"
              >
                <span
                  class="absolute left-0 top-0 -z-[1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-[14px] bg-blue-600 bg-opacity-20 duration-300 group-hover:rotate-45"
                ></span>
                 <img src={img2} className=' w-10'></img>
              </div>
              <h4 class="mb-3 text-xl font-bold text-white ">
              Tailored Assessments
              </h4>
              <p class="mb-8 text-slate-100 lg:text-lg  lg:mb-9">
              Customize assessment criteria, benchmarking, and recommendations to align with your unique needs.
              </p>
              
            </div>
          </div>
          <div class="w-full px-4 md:w-1/3 lg:w-1/4">
            <div class="wow fadeInUp group mb-12" data-wow-delay=".2s">
              <div
                class="relative z-10 mb-10 flex h-[70px] w-[70px] items-center justify-center rounded-[14px] bg-blue-600"
              >
                <span
                  class="absolute left-0 top-0 -z-[1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-[14px] bg-blue-600 bg-opacity-20 duration-300 group-hover:rotate-45"
                ></span>
                <img src={img3} className=' w-10'></img>
              </div>
              <h4 class="mb-3 text-xl font-bold text-white ">
              Continuous Growth
              </h4>
              <p class="mb-8 text-slate-100 lg:text-lg  lg:mb-9">
              Compare your performance against industry standards and competitors, driving continuous improvement.
              </p>
           
            </div>
          </div>
        
        </div>
      </div>
    </section>
            
    </div>
  )
}
